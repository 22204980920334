import React from 'react'

const StationLogoSvg = ({ className, width, height, fill }) => (
  <svg width={width || 250} height={height || 58} viewBox="0 0 250 58" className={className}>
    <text
      id="гастрокосмічна_компанія"
      data-name="гастрокосмічна компанія"
      style={{ fontSize: 25, fill: fill || 'white', fontFamily: 'HappySpratCyrillic2' }}
      x="-0.654"
      y="13.247"
    >
      гастрокосмічна компанія
    </text>
    <text
      id="СТАНЦІЯ"
      style={{ fontSize: 33.333, fill: fill || 'white', fontFamily: 'HappySpratCyrillic2' }}
      x="78.034"
      y="51.979"
    >
      СТАНЦІЯ
    </text>
  </svg>
)

export default StationLogoSvg
