import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import clsx from 'clsx'

import StationLogoSvg from '../assets/stationLogo'
import Logo from '../assets/logo'
import tapasLogo from '../assets/originals/BAR_logo.png'

const Header = ({ siteTitle, showHeader, page }) => (
  <header className={clsx(!showHeader && 'transparent', page || 'main')}>
    <span style={{ width: 120 }} className="left" />
    {showHeader && (
      // <Link to="/" >
      <StationLogoSvg className="logo" />
      // </Link>
    )}
    {(page === 'fabrika' || page === 'green-theater') && <Logo />}
    {page === 'bar' && (
      <div
        style={{
          borderRadius: '50%',
          backgroundColor: 'white',
          width: 140,
          height: 140,
          marginTop: 60,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          transform: 'rotate(11deg)',
        }}
      >
        <img src={tapasLogo} style={{ maxWidth: 150, marginTop: 19 }} />
      </div>
    )}
    {!page && <span style={{ width: 120 }} />}
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
